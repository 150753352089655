<script setup>
import AppLayout from '@/layouts/AppLayout.vue';
import FAQ from '@components/FAQ.vue'
import FindFeatureSection from '@components/FindFeatureSection.vue'
import FindForm from '@components/FindForm.vue'
import FormErrorAlert from '@components/FormErrorAlert.vue'
import SendButton from '@components/SendButton.vue'
import SeparatorBorder from '@components/SeparatorBorder.vue'
import Swal from 'sweetalert2'
import TermsCheckboxes from '@components/TermsCheckboxes.vue'
import { ref, computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useABNStore } from '@stores/ABNStore.js'
import { useErrorsStore } from '@stores/ErrorsStore.js'
import { useFindStore } from '@stores/FindStore.js'
import { useLoadingStore } from '@stores/LoadingStore.js'

const ABNStore = useABNStore()
const store = useFindStore()
const storeErrors = useErrorsStore()
const storeLoading = useLoadingStore()
const { errors } = storeToRefs(storeErrors)

const terms = ref(false)
const info = ref(false)

const tfnNotFound = computed(
  () => errors.value?.tfn?.length
)

const onSubmit = () => {
  storeLoading.setLoading(true)

  const values = {
    ...store.$state,
    birthdate: store.birthdate,
    terms: terms.value,
    info: info.value,
    petition_origin: 'abn-register.com.au',
  }

  if(!store.tfn || !tfnNotFound.value) {
    delete values.tfn;
  }

  axios.post('/api/find', values)
    .then((response) => {
      ABNStore.findingMode(response.data.id)
    })
    .catch((error) => {
      storeLoading.setLoading(false)
      if (error.response.status === 422) {
        storeErrors.setErrors(error.response.data.errors)
      } else if (error.response.data.message === 'No query results for model [App\\Models\\ABN].')  {
        ABNStore.resetFinding()

        const errors = {
          ...error.response.data?.errors,
        }

        errors.tfn = ['TFN needed to proceed']

        storeErrors.setErrors(errors)
      } else {
        ABNStore.resetFinding()
        Swal.fire({
          title: error.response.data.message,
          icon: 'error',
        })
      }
    })
}
</script>

<template>
  <Suspense>
    <AppLayout title="Find">
      <div class="px-6 py-12 bg-white sm:py-32 lg:px-8 isolate bg-white rounded-lg shadow">
        <FindFeatureSection v-once/>
        <SeparatorBorder v-once/>
        <form @submit.prevent="onSubmit" class="flex flex-col space-y-6 sm:space-y-8 lg:space-y-12">
          <FindForm :errors="errors" :tfnNotFound="tfnNotFound"/>
          <SeparatorBorder />
          <FAQ v-once/>
          <SeparatorBorder v-once/>
          <div class="max-w-xl mx-auto flex flex-col space-y-8">
            <TermsCheckboxes
              v-model:terms="terms"
              v-model:info="info"
              :errors="errors"
            />
            <SendButton text="Find my ABN" />
            <FormErrorAlert :errors="errors" />
          </div>
        </form>
      </div>
    </AppLayout>
  </Suspense>
</template>
